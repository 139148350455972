import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './banner1.css'

const Banner1 = (props) => {
  return (
    <div className="banner1-container1 thq-section-padding">
      <div className="banner1-max-width thq-section-max-width">
        <div className="banner1-container2">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="banner1-text5 thq-heading-2">
                  Spank That Bitch
                </h2>
              </Fragment>
            )}
          </h2>
          <p className="banner1-text1 thq-body-large">
            <span className="banner1-text2">
              Do you even
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="banner1-text3">$SPANK</span>
            <span className="banner1-text4">, bro?</span>
          </p>
          <div className="banner1-container3">
            <a
              href="https://t.me/+tqjPmSk3nwA5OTkx"
              target="_blank"
              rel="noreferrer noopener"
              className="banner1-link1"
            >
              <img
                src={props.imageSrc}
                alt={props.imageAlt}
                className="banner1-image1"
              />
            </a>
            <a
              href="https://x.com/spankthatbitch"
              target="_blank"
              rel="noreferrer noopener"
              className="banner1-link2"
            >
              <img
                src={props.imageSrc1}
                alt={props.imageAlt1}
                className="banner1-image2"
              />
            </a>
            <img
              src={props.imageSrc2}
              alt={props.imageAlt2}
              className="banner1-image3"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Banner1.defaultProps = {
  heading1: undefined,
  imageSrc: '/mask%20group%20(12)-200h.png',
  imageAlt: 'image',
  imageSrc1: '/mask%20group%20(11)-200h.png',
  imageAlt1: 'image',
  imageSrc2: '/mask%20group%20(10)-200h.png',
  imageAlt2: 'image',
}

Banner1.propTypes = {
  heading1: PropTypes.element,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  imageSrc1: PropTypes.string,
  imageAlt1: PropTypes.string,
  imageSrc2: PropTypes.string,
  imageAlt2: PropTypes.string,
}

export default Banner1
