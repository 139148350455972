import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './contact10.css'

const Contact10 = (props) => {
  return (
    <div
      className={`contact10-container1 thq-section-padding ${props.rootClassName} `}
    >
      <div className="contact10-max-width thq-section-max-width">
        <div className="contact10-content1 thq-flex-row">
          <div className="contact10-content2">
            <h2>
              {props.heading1 ?? (
                <Fragment>
                  <h2 className="contact10-text20 thq-heading-2">Locations</h2>
                </Fragment>
              )}
            </h2>
            <p>
              {props.content1 ?? (
                <Fragment>
                  <p className="contact10-text23 thq-body-large">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse varius enim in ero.
                  </p>
                </Fragment>
              )}
            </p>
          </div>
        </div>
        <div className="contact10-content3 thq-flex-row">
          <div className="contact10-container2">
            <img
              alt={props.location1ImageAlt}
              src={props.location1ImageSrc}
              className="contact10-image1 thq-img-ratio-1-1"
            />
            <h3>
              {props.location1 ?? (
                <Fragment>
                  <h3 className="contact10-text21 thq-heading-3">Bucharest</h3>
                </Fragment>
              )}
            </h3>
            <div className="contact10-container3">
              <a
                href="https://example.com"
                target="_blank"
                rel="noreferrer noopener"
                className="thq-button-flat thq-body-small"
              >
                <span>
                  Go
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="contact10-text14">$SPANK</span>
                <span> his ass</span>
              </a>
            </div>
          </div>
          <div className="contact10-container4">
            <img
              alt={props.location2ImageAlt}
              src={props.location2ImageSrc}
              className="contact10-image2 thq-img-ratio-1-1"
            />
            <h3>
              {props.location2 ?? (
                <Fragment>
                  <h3 className="contact10-text22 thq-heading-3">
                    Cluj - Napoca
                  </h3>
                </Fragment>
              )}
            </h3>
            <div className="contact10-container5">
              <a
                href="https://example.com"
                target="_blank"
                rel="noreferrer noopener"
                className="thq-button-flat thq-body-small"
              >
                <span>
                  Go
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <span className="contact10-text18">$SPANK</span>
                <span> her ass</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Contact10.defaultProps = {
  location1ImageSrc: '/mask%20group%20(9)-1400w.png',
  heading1: undefined,
  location2ImageSrc: '/kamalaimmigrant-1400w.png',
  location1: undefined,
  rootClassName: '',
  location2: undefined,
  content1: undefined,
  location1ImageAlt: 'image1Alt',
  location2ImageAlt: 'image2Alt',
}

Contact10.propTypes = {
  location1ImageSrc: PropTypes.string,
  heading1: PropTypes.element,
  location2ImageSrc: PropTypes.string,
  location1: PropTypes.element,
  rootClassName: PropTypes.string,
  location2: PropTypes.element,
  content1: PropTypes.element,
  location1ImageAlt: PropTypes.string,
  location2ImageAlt: PropTypes.string,
}

export default Contact10
