import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import { Helmet } from 'react-helmet'

import Navbar8 from '../components/navbar8'
import './spank-game.css'

const SpankGame = (props) => {
  return (
    <div className="spank-game-container1">
      <Helmet>
        <title>Spank-Game - Spank That Bitch</title>
        <meta property="og:title" content="Spank-Game - Spank That Bitch" />
      </Helmet>
      <Navbar8
        link1={
          <Fragment>
            <span className="spank-game-text10 thq-body-small thq-link">
              Spank a Bitch
            </span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="spank-game-text11 thq-body-small thq-link">
              <span>
                Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="spank-game-text13">$</span>
              <span className="spank-game-text14">Spank</span>
            </span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="spank-game-text15 thq-body-small thq-link">
              /leaderboard
            </span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="spank-game-text16 thq-body-small thq-link">
              /about
            </span>
          </Fragment>
        }
        page1={
          <Fragment>
            <span className="spank-game-text17 thq-body-large">Home</span>
          </Fragment>
        }
        page2={
          <Fragment>
            <span className="spank-game-text18 thq-body-large">
              Spank Politicians
            </span>
          </Fragment>
        }
        page3={
          <Fragment>
            <span className="spank-game-text19 thq-body-large">
              Leaderboard
            </span>
          </Fragment>
        }
        page4={
          <Fragment>
            <span className="spank-game-text20 thq-body-large">About</span>
          </Fragment>
        }
        link21={
          <Fragment>
            <span className="spank-game-text21 thq-body-small thq-link">
              Buy $SPANK
            </span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="spank-game-text22">Spank Now!</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="spank-game-text23">
              <span>
                Buy
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="spank-game-text25">$SPANK</span>
            </span>
          </Fragment>
        }
        logoSrc="/spankthatbitchlogo-1500h.webp"
        page1Description={
          <Fragment>
            <span className="spank-game-text26 thq-body-small">
              Spank That Bitch Home Page
            </span>
          </Fragment>
        }
        page2Description={
          <Fragment>
            <span className="spank-game-text27 thq-body-small">
              Spank Politicians Virtually!
            </span>
          </Fragment>
        }
        page3Description={
          <Fragment>
            <span className="spank-game-text28 thq-body-small">
              Check out who got spanked the most!
            </span>
          </Fragment>
        }
        page4Description={
          <Fragment>
            <span className="spank-game-text29 thq-body-small">
              Learn more about Spank That Bitch
            </span>
          </Fragment>
        }
      ></Navbar8>
      <div className="spank-game-container2">
        <div className="spank-game-container3">
          <div className="spank-game-container4">
            <Script
              html={`<script>
  document.addEventListener("DOMContentLoaded", function() {
    // Get the current URL's search params
    const urlParams = new URLSearchParams(window.location.search);
    
    // Get the value of the 'bitch' parameter
    const param = urlParams.get('bitch');
    
    if (param) {
      // Find the iframe on the page (replace '#myIframe' with your iframe's ID or class)
      const iframe = document.querySelector('#myIframe');

      if (iframe) {
        // Get the current src of the iframe
        const iframeSrc = iframe.getAttribute('src');

        // Append the 'bitch' parameter to the iframe's src
        const newIframeSrc = iframeSrc.includes('?') 
          ? \`\${iframeSrc}&bitch=\${param}\` 
          : \`\${iframeSrc}?bitch=\${param}\`;

        // Set the new src for the iframe
        iframe.setAttribute('src', newIframeSrc);
      }
    }
  });
</script>

<iframe id="myIframe" src="https://spank-a-bitch-dev.web.app/spank" frameborder="0" style="width:100%;height:100%"></iframe>
`}
            ></Script>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SpankGame
