import React, { useState, Fragment } from 'react'

import PropTypes from 'prop-types'

import './features24.css'

const Features24 = (props) => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <div
      id={props.features24Id}
      className={`thq-section-padding ${props.rootClassName} `}
    >
      <div className="features24-container2 thq-section-max-width">
        <iframe
          src="https://spank-a-bitch-dev.web.app/spank"
          className="features24-iframe"
        ></iframe>
        <div className="features24-tabs-menu">
          <div
            onClick={() => setActiveTab(0)}
            className="features24-tab-horizontal1"
          >
            <div className="features24-divider-container1">
              {activeTab === 0 && <div className="features24-container3"></div>}
            </div>
            <div className="features24-content1">
              <h2>
                {props.feature1Title ?? (
                  <Fragment>
                    <h2 className="features24-text4 thq-heading-2">
                      Virtual Spanking
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.feature1Description ?? (
                  <Fragment>
                    <span className="features24-text1 thq-body-small">
                      Spank politicians virtually and see who got spanked the
                      most!
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(1)}
            className="features24-tab-horizontal2"
          >
            <div className="features24-divider-container2">
              {activeTab === 1 && <div className="features24-container4"></div>}
            </div>
            <div className="features24-content2">
              <h2>
                {props.feature2Title ?? (
                  <Fragment>
                    <h2 className="features24-text2 thq-heading-2">
                      Leaderboard
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.feature2Description ?? (
                  <Fragment>
                    <span className="features24-text5 thq-body-small">
                      Check the leaderboard to see who&apos;s been spanked the
                      most and climb to the top!
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
          <div
            onClick={() => setActiveTab(2)}
            className="features24-tab-horizontal3"
          >
            <div className="features24-divider-container3">
              {activeTab === 2 && <div className="features24-container5"></div>}
            </div>
            <div className="features24-content3">
              <h2>
                {props.feature3Title ?? (
                  <Fragment>
                    <h2 className="features24-text6 thq-heading-2">
                      Interactive Experience
                    </h2>
                  </Fragment>
                )}
              </h2>
              <span>
                {props.feature3Description ?? (
                  <Fragment>
                    <span className="features24-text3 thq-body-small">
                      Enjoy a fun and interactive experience while spanking
                      those in power!
                    </span>
                  </Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Features24.defaultProps = {
  feature1Description: undefined,
  features24Id: '',
  rootClassName: '',
  feature2Title: undefined,
  feature3Description: undefined,
  feature1Title: undefined,
  feature2Description: undefined,
  feature3Title: undefined,
}

Features24.propTypes = {
  feature1Description: PropTypes.element,
  features24Id: PropTypes.string,
  rootClassName: PropTypes.string,
  feature2Title: PropTypes.element,
  feature3Description: PropTypes.element,
  feature1Title: PropTypes.element,
  feature2Description: PropTypes.element,
  feature3Title: PropTypes.element,
}

export default Features24
